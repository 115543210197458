import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconifyIcon } from '@iconify/react';

export type TButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  label: string,
  className?: string,
  borderRadius?: 'none'| 'sm' | 'md' | 'lg' | 'full',
  variant?: 'outlined' | 'solid'
  disabled?: boolean
  color?: 'blue' | 'yellow'
  icon?: IconifyIcon | string
  iconProps?: { width: number, height: number, className?: string }
};

const Button: FC<TButtonProps> = ({label, className, borderRadius, variant, color, disabled, icon, iconProps = {width: 18, height: 18} as any, ...rest}) => {
  const {t} = useTranslation();

  return (
    <button
      className={`btn
      ${color ? ` btn-${color}` : ''}
      ${borderRadius ? ` rounded-${borderRadius}` : " rounded"}
      ${variant === 'outlined' ? ' btn-outlined' : ''}
      ${variant === 'solid' ? ' btn-bordered' : ''}
      ${disabled ? ' btn-disabled' : ''}
      ${className ? ` ${className}` : ''}`}
      disabled={disabled}
      {...rest}
    >
      {icon && <Icon icon={icon} {...iconProps} />}
      <div>{t(label)} </div>
    </button>
  );
};

export default Button;
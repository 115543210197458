import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TopArrow from '../../../assets/images/up-arrow.png';
// Components
import { LOCAL_STORAGE } from '../../../types/localStorage';
import MobileNav from './MobileHeader';
import SearchField from './components/search/SearchField';
// Modals
import LoginLang from './components/LoginLang';
import DesktopNav from './components/DesktopNav';
import { INavLink } from './components/types';
import RegisterModal from '@common/modals/register/RegisterModal';
import { LoginModal } from '@common/modals/login';
import PasswordResetModal from '@common/modals/reset-password/PasswordResetOld';
import { logoutUser } from '@http/endpoints/user';
import Logo from '@images/BU-logo/resize/BUM-logo.png';
import { isUserInfoModel, useStoreActions, useStoreState } from '@store';
import { getLocalStorageItem, getLocalStorageObj } from '@helpers/getLocalStorage';
import { RegistrationPages } from '@common/modals/register/context/d';

const Header = () => {
  const [showSearch, setShowSearch] = useState<boolean | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showBTT, setShowBTT] = useState<boolean>(false);

  const [showPasswordReset, setShowPasswordReset] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const { isAuthenticated, userInfo, isUsedRegistrationPromo } = useStoreState((state) => state.user);
  const location = useLocation();
  const { token } = useStoreState((state) => state.user.session.sessionInfo);

  const { removeAuthInfo, updateUser } = useStoreActions((actions) => actions.user);

  const { isRegistrationFlow } = useStoreState((state) => state);
  const { setShowLogin, setShowRegisterBonus } = useStoreActions((actions) => actions);
  const history = useHistory();
  const isDesktop = useMedia('(min-width: 1024px)');

  const countRef = useRef(0);
  const { t } = useTranslation();

  const navLinks: INavLink[] = [
    { path: '/top-100', name: t('TOP 100') },
    { path: '/movies/all', name: 'Movies' },
    { path: '/scenes/all?page=1', name: 'Scenes' },
    { path: '/categories?page=1', name: 'Categories' },
    { path: '/stars?featured_page=1&page=1', name: 'Erotic Stars' },
    { path: '/studios?page=1', name: 'Studios' },
    {
      path: 'https://beateuhselive.com/Landing/joinmenow_beateuhselive-cms-com_de.html?ADR=-100min&Language=de',
      name: 'Webcams',
      externalLink: true,
    },
    {
      path: '/movies/svod',
      name: isUserInfoModel(userInfo) && userInfo.svod_member ? 'VIP+' : 'JOIN VIP+',
      club: true,
    },
  ];

  useEffect(() => {
    setShowSearch(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
  }, []);

  useEffect(() => {
    if (token && countRef.current === 0) {
      updateUser();
      countRef.current = 1;
    }
  }, [token, updateUser, countRef]);




  useEffect(()=> {
    if (location.pathname === '/auth/payment-success' || location.pathname === '/failed'|| location.pathname === '/success') return;
    if(!isAuthenticated || isRegistrationFlow) return;
    if(isUsedRegistrationPromo) return;
    localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN, '1');
    localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_DATA, JSON.stringify({
      activePage: RegistrationPages.REGISTER_PROMO_CODE_PICKER,
      isNeedBack: false,
      showCloseIcon: false,
      formData: {
        email: (userInfo as any)?.email,
        password: 'ttttt!',
        password2: 'ttttt!',
        termsConditions: true
      }
    }));
    setShowRegisterBonus(true);
  }, [location, isRegistrationFlow, userInfo, isAuthenticated, isUsedRegistrationPromo]);



  // Check user token is valid

  /**
   * Handle Window Scroll
   */
  const handleWindowScroll = () => {
    // if (window.scrollY > 50) setHeaderHeight("12");
    // else setHeaderHeight("16");
    if (window.scrollY > 200) setShowBTT(true);
    else setShowBTT(false);
  };
  const handleShowSearch = useCallback(() => {
    setShowSearch(!showSearch);
  }, [showSearch]);
  const handleShowMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const handleLoginShow = useCallback(() => {
    setShowLogin(true);
  }, [setShowLogin]);

  const handleToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  /**
   * Handle Logout
   * @returns {Promise<void>}
   */
  const handleLogout = useCallback(async () => {
    if(isWaiting) return;
    try {
      if(!isWaiting) setIsWaiting(true);
      const { data } = await logoutUser();
      if (data) {
        await removeAuthInfo();
        setShowRegisterBonus(false);
        setShowLogin(false);
        setTimeout(() => history.push('/'), 500);
      }
    }catch (e) {
       /** */
    }finally {
      setIsWaiting(false);
    }
  }, [removeAuthInfo, history, setIsWaiting, isWaiting, setShowRegisterBonus, setShowLogin]);

  return (
    <header className="top-0 w-full left-0 lg:py-0 fixed z-20 shadow-lg h-auto">
      <div
        onKeyDown={handleToTop}
        role="button"
        tabIndex={0}
        className={`back-to-top ${
          showBTT ? 'show-btt' : 'hide-btt'
        } opacity-75 fixed bottom-0 mb-3 mr-3 right-0 cursor-pointer`}
        onClick={handleToTop}
      >
        <LazyLoadImage src={TopArrow} alt="bact to top arrow" className="" />
        {/*<img loading="lazy" src={TopArrow} alt="bact to top arrow" className="" />*/}
      </div>
      <nav
        className={`hidden lg:flex lg:shadow-none items-center justify-center md:justify-between lg:justify-start px-3 sm:px-6 md:px-8 lg:px-8 z-20`}
      >
        <div className="side-nav-container hidden md:inline-block lg:hidden space-x-4 w-2/5">
          {!showMenu ? (
            <button className="mr-4 focus:outline-none" onClick={() => setShowMenu(true)}>
              <i className="fas fa-bars text-2xl text-black" />
            </button>
          ) : (
            <button className="mr-4  focus:outline-none">
              <i className="fas fa-bars text-2xl text-black" />
            </button>
          )}

          <button className="mr-4  opacity-50 focus:outline-none" onClick={handleShowSearch}>
            <i className="fas fa-search text-2xl text-black"></i>
          </button>
        </div>
        <div className="inline-flex items-center justify-center xl:justify-start">
          <div
            className={`site-logo md:absolute mx-auto left-0 right-0 lg:static transition-all duration-300 ease-in-out`}
          >
            <Link
              to="/"
              onClick={(e) => {
                if (window.location.pathname === "/") {
                  e.preventDefault();
                  handleToTop();
                }
              }}
            >
              <LazyLoadImage className="w-full pt-2 xxl:pt-0" src={Logo} alt="site logo" />
              {/*<img loading="lazy" className="w-full pt-2 xxl:pt-0" src={Logo} alt="site logo" />*/}
            </Link>
          </div>
        </div>
        <div className="hidden lg:inline-block w-full ml-10 pt-2">
          <div className="flex items-center">
            {isDesktop ? <SearchField hideSearch={() => setShowSearch(false)} /> : null}
            {isUserInfoModel(userInfo) && userInfo?.deposit && (
              <div className="flex ml-2 justify-center items-center text-center px-1">
                <p className="header-deposit-text text-sm rounded flex-nowrap inline-block text-mainRed work-sans-font opacity-75">
                  {`Kto.-St. ${Number(userInfo.deposit).toFixed(2).replace('.', ',')} €`}
                </p>
              </div>
            )}
            {isAuthenticated && (
              <div
                onKeyDown={handleLogout}
                role="button"
                tabIndex={0}
                className="cursor-pointer hover:opacity-75 lg:bg-mainRed lg:text-white lg:py-1 rounded-md lg:text-center ml-2 px-2"
                onClick={handleLogout}
              >
                <i className="fas fa-sign-out-alt" />
              </div>
            )}
            <LoginLang handleLoginShow={handleLoginShow} />
          </div>
          <div className="flex hidden xxl:flex">
            <DesktopNav navLinks={navLinks} />
          </div>
        </div>
      </nav>
      <div className="hidden lg:flex xxl:hidden">
        <DesktopNav navLinks={navLinks} />
      </div>

      <MobileNav
        navLinks={navLinks}
        handleShowSearch={handleShowSearch}
        showMenu={showMenu}
        setShowMenu={handleShowMenu}
        handleLoginShow={handleLoginShow}
      />
      <div
        className={`${
          showSearch ? 'search-show' : 'search-hide'
        } mobile-search w-full lg:hidden shadow-inner bg-white ease-out`}
      >
        {isDesktop ? null : <SearchField mobile={true} hideSearch={() => setShowSearch(false)} />}
      </div>
      {showPasswordReset && <PasswordResetModal show={showPasswordReset} hide={() => setShowPasswordReset(false)} />}
    </header>
  );
};

export default Header;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from '../../buttons/Button';
import styles from './AdultWarningModal.module.scss';
import Modal from '@common/modals/modal/BaseModal';
import { COOKIES } from '@common/modals/subscribe/types';
import adultLogo from '@assets/images/adult-warning/logo-adult.svg';

const AdultWarning = () => {
  const { t } = useTranslation();

  const timer = useRef<NodeJS.Timer | number>(0);
  const [showModal, setShowModal] = useState(false);

  const handleHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  useEffect(() => {
    return () => {
      handleHide();
    };
  }, []);

  useEffect(() => {
    const cookieValue = Cookies.get(COOKIES.BUM_ADULT_WARNING);
    if (cookieValue) {
      if (timer?.current) {
        clearTimeout(timer.current);
        timer.current = 0;
      }
      return;
    }
    timer.current = setTimeout(() => {
      if (!cookieValue) {
        setShowModal(true);
      }
    }, 500);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = 0;
      }
    };
  }, [setShowModal]);

  const handleOver18 = useCallback(() => {
    Cookies.set(COOKIES.BUM_ADULT_WARNING, '1', { expires: 365 });
    setShowModal(false);
  }, [setShowModal]);

  const handleUnder18 = useCallback(() => {
    window.open('http://google.com/', '_blank');
  }, []);


  if (!showModal) return <></>;

  return (
    <Modal
      show={true}
      hide={handleHide}
      center
      noPadding
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      isDarken
      overlayClassName={styles.adultWarningModalOverlay}
    >
      <div className={styles.adultWarningContainer}>
        <div className={styles.adultWarningLogo}>
          <LazyLoadImage src={adultLogo} alt={'Logo'} />
        </div>
        <div className={styles.adultWarningContent}>
          <h4 className={styles.adultWarningTitle}>{t('This website is intended for adults only')}</h4>
          <div className={styles.adultWarningText}>
            {t('This website contains age-restricted content, including nude images and explicit depictions of sexual activity. By using this website, you confirm that you are at least 18 years old or have reached the age of majority in your jurisdiction. You also explicitly agree to view sexually explicit content.')}
          </div>
          <div className={styles.adultWarningButtonsContainer}>
            <Button
              label={'I`m under 18'}
              variant={'outlined'}
              className={`${styles.adultWarningButton}`}
              onClick={handleUnder18}
            />
            <Button
              label={'I`m 18+'}
              variant={'solid'}
              className={`${styles.adultWarningButton} ${styles.buttonApprove}`}
              color={'yellow'}
              icon={'mdi:check'}
              iconProps={{
                width: 24,
                height: 24,
                className: styles.buttonCheckIcon,
              }}
              onClick={handleOver18}
            />
          </div>
        </div>
        <div className={styles.adultWarningSubText}>
          <Trans
            i18nKey="<b>The protection of minors is very important to us. At the same time, we believe that every adult should have the freedom to choose the content they wish to view.</b> For this reason, we will show you a way to set up parental controls that will allow you to restrict access to this website. Visit the <a>Parental Controls</a> page for step-by-step instructions on how to easily block access to this website."
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href="https://www.asacp.org/index.html?content=parental_guidelines" target="_blank"
                    rel="noopener noreferrer" />,
              b: <b></b>,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};


export default AdultWarning;
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE } from '../../../../types/localStorage';
import Registration from '@common/modals/register/components/Registration';
import Modal from '@common/modals/modal/BaseModal';
import RegistrationContextContainer from '@common/modals/register/context';
import RegistrationContext from '@common/modals/register/context/context';
import Login from '@common/modals/login/Login';
import PasswordReset from '@common/modals/reset-password/PasswordReset';
import { getLocalStorageObj, removeLSItem } from '@helpers/getLocalStorage';
import { RegistrationPages } from '@common/modals/register/context/d';
import RegistrationPromoCodePicker from '@common/modals/register/components/registerPromo';
import { useStoreActions, useStoreState } from '@store';

const Forms = ({hide}: {hide: ()=> void}) => {
  const {isLogin, isReset, isRegPromo, setRegister, setReset} = useContext(RegistrationContext);
  if(isReset) return  <PasswordReset hide={hide}/>;
  if(isLogin) return  <Login hide={hide} handleSwithRegister={setRegister} handleResetShow={setReset}/>;
  if(isRegPromo) return <RegistrationPromoCodePicker hide={hide} />;
  return  <Registration hide={hide}/>;
};

const RegisterModal= () => {
  const location = useLocation();
  const {setRegistrationFlow, setShowLogin, setRedirectPath} = useStoreActions((actions ) => actions);
  const { showLogin, isRegistrationFlow, showRegisterBonus} = useStoreState((state) => state);
  const [entryData, setEntryData] = useState<any>({});
  const [showCloseIcon, setCloseIcon] = useState<boolean>(true);
  
  const hide = () => {
    setShowLogin(false);
    setRedirectPath(null);
  };
  
  const removeLocalStorage = () => {
    removeLSItem(LOCAL_STORAGE.REGISTRATION_MODAL_AUTO_OPEN);
    removeLSItem(LOCAL_STORAGE.REGISTRATION_MODAL_DATA);
    removeLSItem(LOCAL_STORAGE.REGISTRATION_SUCCESS);
  };
  
  const handleFn = ()=> {
    const data = getLocalStorageObj(LOCAL_STORAGE.REGISTRATION_MODAL_DATA);
    if(!data) {
      return;
    }
    const isSuccess = localStorage.getItem(LOCAL_STORAGE.REGISTRATION_SUCCESS);
    if(!isSuccess) return;
    setRegistrationFlow(true);
    setCloseIcon(data?.showCloseIcon !== undefined ? data?.showCloseIcon : true);
    setEntryData({
      lastPage: data.activePage,
      isNeedBack: false,
      activePage: (()=> {
        if(!isSuccess) return data.activePage;
        switch(data.activePage) {
          case RegistrationPages.VIP: return RegistrationPages.VIP_FINISH;
          case RegistrationPages.BONUS: return RegistrationPages.BONUS_FINISH;
          case RegistrationPages.PROMO: return RegistrationPages.PROMO_FINISH;
          default: return data.activePage;
        }
      })(),
      formData: data.formData
    });
    removeLocalStorage();
  };

  useEffect(() => {
    if (location.pathname === '/auth/payment-success' || location.pathname === '/failed'|| location.pathname === '/success' || isRegistrationFlow) return;
    if(showRegisterBonus) {
      removeLocalStorage();
      return;
    }
    handleFn();
  }, [handleFn, location, isRegistrationFlow, showRegisterBonus]);


  useEffect(() => {
    return () => {
      hide();
    };
  }, []);

  if(!showLogin) return <></>;

  return (
    <Modal show={true} hide={hide} center noPadding closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={showCloseIcon}>
      <div className="registration-modal p-5">
        <RegistrationContextContainer entryData={entryData}>
          {/*<BackgroundImages />*/}
          <div className={'wizard-container'}>
            <Forms hide={hide}/>
          </div>
        </RegistrationContextContainer>
      </div>
    </Modal>
  );
};

export default RegisterModal;

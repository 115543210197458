type SendDataModel = {
  email: string;
  current_page: string;
  clicker_id?: string;
};



export enum COOKIES {
  BUM_SUBSCRIBED='bum_subscribed',
  BUM_SUBSCRIBED_EXPIRES='bum_subscribed_expires',
  BUM_ADULT_WARNING = 'bumAdultWarning'
}


export type { SendDataModel };

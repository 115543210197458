/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'de',
    debug: process.env.NODE_ENV !== 'production' ? false : false,
    //lng: "de",
    load: 'languageOnly',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });

const fetchAndSetLanguage = async () => {
  try {
    if(localStorage.getItem('user_lang')) {
      return;
    }
    const detectedLanguage = navigator?.language?.split("-")?.[0];
    const lang = detectedLanguage === 'de' ? 'de' : 'en';
    // Change language based on detection or fallback to 'de'
    if (detectedLanguage) {
      i18n.changeLanguage(lang);
      localStorage.setItem('user_lang', lang);
    } else {
      i18n.changeLanguage('de');
      localStorage.setItem('user_lang', lang);
    }
  } catch (error) {
    console.error('Error detecting language:', error);
    i18n.changeLanguage('de'); // Fallback to German
    localStorage.setItem('user_lang', 'de');
  }
};

// Call this function on app initialization
fetchAndSetLanguage();

export default i18n;
import { action } from 'easy-peasy';
import { StarsModel } from '@store/model/types';


const starsModel: StarsModel = {
  page: 1,
  list: {},
  searchValue: '',
  currentLetter: '',
  isPopular: true,
  hasMore: true,
  scrollPosition: 0,
  setPage: action((state, payload) => {
    state.page = payload;
  }),
  setSearch: action((state, payload) => {
    state.searchValue = payload;
    state.list = {};
    state.currentLetter = '';
    state.isPopular = false;
    state.page = 1;
  }),
  setLetter: action((state, payload) => {
    state.currentLetter = payload;
    state.page = 1;
    state.searchValue = '';
    state.list = {};
    state.isPopular = false;
  }),
  setPopular: action((state, payload) => {
    state.isPopular = !state.isPopular;
    state.page = 1;
    state.list = {};
    state.searchValue = '';
    state.currentLetter = '';
  }),
  clearPopular: action((state) => {
    state.isPopular = false;
    state.page = 1;
    state.list = {};
    state.searchValue = '';
    state.currentLetter = '';
  }),
  clearSearch: action((state) => {
    state.searchValue = '';
  }),
  clearLetter: action((state) => {
    state.currentLetter = '';
    state.page = 1;
    state.list = {};
    state.isPopular = false;
  }),
  clearList: action((state) => {
    state.list = {};
  }),
  setList: action((state, payload) => {
    state.list = {
      ...state.list,
      [payload.page] : payload.list
    };
  }),
  getNextPage: action((state) => {
    state.page = state.page + 1;
  }),
  setHasMore: action((state, payload) => {
    state.hasMore = payload;
  }),
  setScrollPosition: action((state, payload) => {
    state.scrollPosition = payload;
  }),
  setData: action((state, payload) => {
    state.hasMore = payload.hasMore;
    state.page = payload.page;
    state.scrollPosition = payload.scrollPosition;
    if(payload.isPopular) state.isPopular = payload.isPopular;
    if(payload.searchValue)state.searchValue = payload.searchValue;
    if(payload.currentLetter) state.currentLetter = payload.currentLetter;
  }),
  resetData: action((state) => {
    state.page = 1;
    state.hasMore= true;
    state.scrollPosition = 0;
    state.isPopular = true;
    state.searchValue = '';
    state.currentLetter = '';
    state.list = {};
  }),
};


export default starsModel;
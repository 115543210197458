import { FC, ReactNode, useCallback } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useStoreState } from '@store';

type Props = {
  show: boolean;
  hide: () => void;
  center?: boolean
  children: ReactNode;
  noPadding?: boolean
  closeOnOverlayClick?: boolean,
  closeOnEsc?: boolean
  showCloseIcon?: boolean
  isDarken?:boolean,
  overlayClassName?: string
};
const BaseModal: FC<Props> = ({ show, hide, center, children,  noPadding, closeOnOverlayClick, closeOnEsc = true, showCloseIcon= true, isDarken = false, overlayClassName }) => {
  const { showLoading } = useStoreState((state) => state);

  const customStyles = {
    modal: {
      animation: `${show ? 'customEnterAnimation' : 'customLeaveAnimation'} 500ms`,
      background: 'rgb(240, 240, 240)',
      borderRadius: '15px',
      padding: !noPadding ? '9px' : '0'
    },
    overlay: {
      background: isDarken ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
      padding: '2px',
      backdropFilter: 'blur(10px)'
    },
  };
  
  const handleLoading = useCallback(()=> {
    if(showLoading) return;
    hide();
  },[hide, showLoading]);
  
  return (
    <Modal
      open={show}
      onClose={handleLoading}
      styles={customStyles}
      center={center}
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
      showCloseIcon={showCloseIcon && !showLoading}
      classNames={{
        overlay: overlayClassName
      }}
    >
      {children}
    </Modal>
  );
};

export default BaseModal;
